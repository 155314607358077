import React, { useState } from 'react';
import './index.css';
import ArberShabani from "../../images/team/arbershabani.jpeg";

const Profile = () => {
  const [page, setPage] = useState(1);

  const { name, image, title, about, profile, contact, talks, interests, experiences: experiencesList, openSource, recommendations: recommendationsList } = {
    name: 'Arbër Shabani',
    image: ArberShabani,
    title: 'Senior Software Engineer | React & React Native Expert | CEO of Coetic X | Helicopter Pilot',
    about: `As a highly motivated and skilled Software Engineer, I bring a wealth of experience across multiple industries, specializing in JS technologies. Whether it's fintech, healthcare, logistics, e-commerce, or aviation, I'm passionate about delivering high-quality work and being efficient. On a personal note, I enjoy pursuing a variety of hobbies, including flying helicopters, freediving, and playing piano.`,
    // about: `Experienced and motivated Software Engineer specialized in JS technologies across multiple industries, delivering high-quality work efficiently. Pursues hobbies in flying, freediving, and playing piano.`,
    // about: `Experienced Software Engineer in JS technologies across multiple industries, delivering high-quality work efficiently, and pursuing hobbies in flying, freediving, and piano.`,
    profile: [
      {
        text: 'Arbër Shabani',
        icon: <span class="material-icons">person</span>,
      },
      {
        text: '21 January, 1997',
        icon: <span class="material-icons">calendar_month</span>,
      },
      {
        text: 'Kosovo',
        icon: <span class="material-icons">map</span>,
      },
      {
        text: 'Albanian, English, German',
        icon: <span class="material-icons">language</span>,
      },
    ],
    contact: [
      {
        text: '+383 45 250 180',
        icon: <span class="material-icons">phone</span>,
      },
      // {
      //   text: '+43 664 996 449 28',
      //   icon: <span class="material-icons" style={{ width: 18 }}></span>,
      //   // icon: <span class="material-icons">phone</span>
      // },
      {
        text: 'arber@coetic-x.com',
        icon: <span class="material-icons">alternate_email</span>,
      },
      {
        text: 'Rr. Ahmet Krasniqi 5-1 \nPrishtina 10000, Kosova',
        icon: <span class="material-icons">location_on</span>,
      },
    ],
    talks: [
      {
        // text: 'Expert Talk',
        // text: 'Optimizing React Code for Scale',
        // icon: <span class="material-icons">co_present</span>,
        // icon: <span class="material-icons">record_voice_over</span>,
        image: 'https://img.youtube.com/vi/UUocCoDdqfg/hqdefault.jpg',
        // image: 'https://firebasestorage.googleapis.com/v0/b/me-arber.appspot.com/o/Optimizing%20React%20Code%20for%20Scale%205-57%20screenshot.png?alt=media&token=090c8422-bf85-4180-8fc6-b82f32c2eeb9&_gl=1*1rdhqzr*_ga*MjAyMTI3MDc2LjE2NzA3NTQxOTU.*_ga_CW55HF8NVT*MTY4NjY2NTg2NS4yNS4xLjE2ODY2NjU5MDkuMC4wLjA.',
        link: 'https://youtu.be/UUocCoDdqfg',
      },
    ],
    interests: [
      {
        text: 'Freediving',
        icon: <span class="material-icons">water</span>,
        image:
          'https://firebasestorage.googleapis.com/v0/b/me-arber.appspot.com/o/Freediving%2026%20meters%20in%20Y-40%200-35%20screenshot%20(1).png?alt=media&token=53c98008-0615-4d9e-a23c-9b3fa38d28ec&_gl=1*97s97q*_ga*MjAyMTI3MDc2LjE2NzA3NTQxOTU.*_ga_CW55HF8NVT*MTY4NjEyNzEyOS4yMi4xLjE2ODYxMjczMDMuMC4wLjA.',
      },
      {
        text: 'Playing Piano',
        icon: <span class="material-icons">piano</span>,
        image:
          'https://firebasestorage.googleapis.com/v0/b/me-arber.appspot.com/o/1686126636638%20(1).jpg?alt=media&token=873dca54-d8de-42a3-831a-618526918300&_gl=1*r4vmok*_ga*MjAyMTI3MDc2LjE2NzA3NTQxOTU.*_ga_CW55HF8NVT*MTY4NjEyNzEyOS4yMi4xLjE2ODYxMjczMTkuMC4wLjA.',
      },
      {
        text: 'Flying Helicopters',
        icon: <span class="material-icons">local_airport</span>,
        image:
          'https://firebasestorage.googleapis.com/v0/b/me-arber.appspot.com/o/1686127015065.jpg?alt=media&token=3da3e312-0f7d-408f-b53b-1591864aa352&_gl=1*rhl1xf*_ga*MjAyMTI3MDc2LjE2NzA3NTQxOTU.*_ga_CW55HF8NVT*MTY4NjEyNzEyOS4yMi4xLjE2ODYxMjcyODEuMC4wLjA.',
      },
    ],
    experiences: [
      {
        // time: 'May 2021 - Present · 2 yrs',
        time: '2021 - <span style="font-weight:500">Present</span>',
        title: 'Coetic X',
        previousTitle: '',
        // position: 'Senior Software Engineer & Chief Executive Officer · On-site',
        position: 'Senior Software Engineer & Chief Executive Officer',
        // content: `I lead Coetic X's software engineering projects for diverse clients.`,
        content: `As the founder of Coetic X, I oversee the strategy development and execution of our software engineering projects for a diverse range of clients. `,
        // content: "As Coetic X founder, I oversee strategy for diverse software projects.",
        // content: `As the founder and CEO of Coetic X, I oversee the strategy development and execution of our software engineering projects for a diverse range of clients, both directly and through intermediary companies. Our portfolio includes a variety of successful software products and platforms in industries such as Aviation, Logistics, E-commerce, Healthcare, Tax Automation, Payments, Blockchain, and Social Apps. At Coetic X, we don't just provide engineering services; we develop and deliver innovative software solutions that meet the unique needs and goals of each of our clients.`,
        projects: [
          {
            title: 'Work with Coetic X clients',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [
              {
                title: "Restaurant Chain POS System",
                role: "Team Lead",
                duration: '3 months',
                nda: true,
                description: "Lead a team of 3 frontend developers on the implementation of the POS system.",
              },
              {
                title: "Accointing by Glassnode",
                titleExtras: 'Blockchain Taxes',
                role: "Product Engineer",
                duration: '1.3 years',
                nda: false,
                // description: "Worked on crypto tax reports and calculations, implemented highly intricate features including fee calculations, using a powerful tech stack comprising NestJS, Postgres, React, Nx and more. Established the Cypress end-to-end testing framework.",
                // description: "I managed crypto tax reports and calculations, developed complex features like fee calculations using NestJS, Postgres, React, Nx, and established the Cypress end-to-end testing framework."
                description: "I specialized in crypto tax reports, complex fee calculations using NestJS, Postgres, React, Nx, and implemented the Cypress end-to-end testing framework.",
              },
              {
                title: "Dearest.Life",
                titleExtras: 'Healthcare (prev. HelloDearest)',
                role: "Chief Technical Officer",
                duration: '9 months',
                nda: false,
                // description: "Responsible for managing all technical aspects of the project. My role involved developing the mobile application using React Native with TypeScript, storing the app's content in Strapi CMS and building the web interface using NextJS and using Hasura and GraphQL for backend.",
                // description: "I managed all technical aspects, developing the React Native mobile app with TypeScript, using Strapi CMS for content, and building the NextJS web interface with Hasura and GraphQL for backend operations.",
                // description: "Led technical aspects, developed React Native app (TypeScript), used Strapi CMS for content, built NextJS web interface, employed Hasura and GraphQL for backend."
                description: "Led tech, React Native app (TypeScript), Strapi CMS, NextJS web, Hasura & GraphQL."
              },
              {
                title: "Fashion Tech",
                titleExtras: 'Virtual Try On',
                role: "Senior Frontend Engineer",
                duration: '7 months',
                nda: true,
                // description: "Implemented a Virtual Try On SDK with a Shopify integration for the clients, restructured the app and aded a few mobile features. Implemented an internal dashboard for managing client data.",
                // description: "I integrated a Virtual Try On SDK with Shopify for clients, restructured the app, added mobile features, and developed an internal dashboard for client data management."
                description: "Integrated SDK with Shopify, restructured app, and built client dashboard."
              },
              // {
              //   title: "FASK - Autosports Federation of Kosovo (License Management)",
              //   role: "Team Lead",
              //   duration: '4 months',
              //   nda: false,
              //   description: "",
              // },
              // {
              //   title: "Fidola",
              //   role: "Frontend Engineer",
              //   duration: '1 month',
              //   nda: false,
              //   description: "",
              // },
              // {
              //   title: "7bridges DevTool",
              //   role: "Senior Frontend Engineer",
              //   duration: '2 weeks',
              //   nda: false,
              //   description: "",
              // },
              // "Dating App",

            ],
          },
          {
            title: 'In partnership with Povio',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [
              {
                title: "Identity Management",
                titleExtras: 'Blockchain',
                // role: "Senior Software Engineer",
                duration: '7 months',
                nda: false,
                // description: "I worked on the mobile app - working with a third party SDK and managing complex native operations.",
                // description: "I focused on the mobile app, integrating a third-party SDK and managing intricate native operations."
                description: "Managed mobile app, integrated third-party SDK, handled complex native operations."
              },

              {
                title: "Music Documentaries",
                // role: "Senior Software Engineer",
                duration: '1 year',
                nda: false,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                description: "I managed the entire system: React, Contentful, AWS, GraphQL, and more for the project."
              },
              {
                title: "Furniture Logistics",
                titleExtras: 'E-commerce',
                // role: "Senior Frontend Engineer",
                duration: '7 months',
                nda: false,
                // description: "I worked on frontend tasks, including innovative feature implementation, for a NY logistics company specializing in second-hand sales.",
                description: "Handled frontend tasks, innovating features for a NY logistics firm in second-hand sales."
              },
              {
                title: "Logistics & Data Science",
                titleExtras: 'Supply Chains',
                // role: "Senior Frontend Engineer",
                duration: '1 year',
                nda: false,
                // description: "I refactored, added features, fixed bugs, and optimized code performance for a UK data science industry client. ",
                description: "Refactored, added features, fixed bugs, and optimized code for a UK data science client."
              },
              {
                title: "Sleep Industry",
                titleExtras: 'E-commerce',
                // role: "Senior Frontend Engineer",
                duration: '2 weeks',
                nda: false,
                // description: "I used NextJS and integrated Shopify while restructuring and organizing the codebase at a NY tech company.",
                description: "Integrated Shopify and NextJS, restructured and organized code at NY tech firm."
              },
            ],
          },
          {
            title: 'In partnership with Changing Digital',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [
              {
                title: "Real Estate",
                titleExtras: 'Property Tech',
                // role: "Senior Software Engineer",
                // duration: '7 months',
                nda: true,
                // description: "I worked on the mobile app - working with a third party SDK and managing complex native operations.",
                // description: "I focused on the mobile app, integrating a third-party SDK and managing intricate native operations."
                description: "Managed mobile app, integrated Auth0, upgraded existing app to new versions."
              },
              {
                title: "Ode Site Checker",
                titleExtras: 'Environmental',
                // role: "Senior Frontend Engineer",
                duration: '2 weeks',
                nda: false,
                // description: "I used NextJS and integrated Shopify while restructuring and organizing the codebase at a NY tech company.",
                description: "Cypress website analyzing page performance, delay and environmental impact."
              },
            ],
          },
          {
            title: 'In partnership with Orange Melons',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [
              {
                // title: "Terminal POS Integration (Restaurant chain)",
                title: "Terminal POS Integration",
                titleExtras: 'Restaurant Chain',
                // role: "Senior Software Engineer",
                duration: '2 weeks',
                nda: false,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                description: "I set up Adyen with terminal POS integration for a short-term project."
              },
              // " - NDA",
              // "Global Tax Automation Platform (Fintech) - NDA" 
            ],
          },
          {
            title: 'In partnership with UpStack',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [
              {
                // title: "Payments Service (Fintech)",
                title: "Payments Service",
                titleExtras: 'Fintech',
                // role: "Senior Software Engineer",
                duration: '2 weeks',
                nda: true,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                // description: "Work on a SDK system that accepts all types of payments and configures payment workflows",
                description: "Worked in an SDK for accepting various payments and configuring payment workflows."
              },
              {
                // title: "Global Tax Automation Platform (Fintech)",
                title: "Global Tax Automation Platform",
                titleExtras: 'Fintech',
                // role: "Senior Software Engineer",
                duration: '2 weeks',
                nda: true,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                // description: "I worked on a large scale platform with next js on generating invoices and managing and automating country based taxes
                description: "Built platform with Next.js for invoices and automated country-based tax management."
              },
              // " - NDA",
              // " - NDA"
            ],
          },
          {
            title: 'In partnership with Proxify',
            // description:
            //   "Worked on projects as a software engineer",
            projects: [

              {
                // title: "Global Tax Automation Platform (Fintech)",
                title: "Porall - Human United",
                titleExtras: 'Healthcare App',
                // role: "Senior Software Engineer",
                // duration: '2 weeks',
                nda: true,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                description: "Developed app with React Native, MobX, and innovative breathalyzer features.",
                // description: "Worked on the app with React Native & MobX, implemented interesting features & breathalyzer functionalities."
              },

              {
                // title: "Global Tax Automation Platform (Fintech)",
                title: "JanneApp",
                titleExtras: 'Nightlife App',
                // role: "Senior Software Engineer",
                // duration: '2 weeks',
                nda: true,
                // description: "I worked on the whole system - React, Contentful, AWS, GraphQL and more across the project.",
                description: "Led the development with React Native, Expo, and Redux for a nightlife application.",
                // description: "As the lead of the mobile app team at Janne company, I spearheaded the development of a social connection mobile app. Leveraging technologies such as React Native, Expo, and Redux for state management, I ensured efficient and seamless functionality."
              },
            ],
            // links: [
            //   " ()",
            //   " ( App)",
            // ],
          },

          // {
          //   title: 'Pilot Planner (Course App w/ Dashboard)',
          //   description:
          //     "From ideation to completion, I led my internal team in bringing this project to life. With my expertise and strategic approach, we delivered a successful implementation, ensuring the project's vision was realized. We leveraged various technologies such as React, React Native, Firebase, Firestore to ensure efficient and reliable functionality.",
          //   links: [
          //     'https://apps.apple.com/us/app/pilot-planner/id1663899565?platform=iphone',
          //     'https://play.google.com/store/apps/details?id=com.coeticx.pilotplanner',
          //     'https://pilot-planner.com/',
          //   ],
          // },
          // {
          //   title: 'Accointing by Glassnode (Contractor)',
          //   // nda: true,
          //   description:
          //     'Worked on crypto tax reports and calculations, implemented highly intricate features including fee calculations, using a powerful tech stack comprising NestJS, Postgres, React, Nx and more. Established the Cypress end-to-end testing framework.',
          //   links: ['https://accointing.com/'],
          // },
          // {
          //   title: 'Fashion Tech',
          //   nda: true,
          //   description:
          //     'Implemented a Virtual Try On SDK with a Shopify integration for the clients, restructured the app and aded a few mobile features. Implemented an internal dashboard for managing client data.',
          // },
          // {
          //   title: 'HelloDearest (Relationship Health)',
          //   description:
          //     "Responsible for managing all technical aspects of the project. My role involved developing the mobile application using React Native with TypeScript, storing the app's content in Strapi CMS and building the web interface using NextJS and using Hasura and GraphQL for backend.",
          //   links: [
          //     'https://apps.apple.com/rs/app/dearest-love-healthier/id1583462259',
          //     'https://play.google.com/store/apps/details?id=io.hellodearest.dearest',
          //     'https://www.hellodearest.io/',
          //   ],
          //   // Worked on the whole platform - frontend, backend and app. Implemented new features and updated functionality.
          //   // '
          //   // },{
          //   //     title: 'Fintech (Payments Service)',
          //   //     nda: true,
          //   //     description: 'Worked on frontend, implementing intricate features on payments methods provided by the system.'
          //   // },{
          //   //     title: 'Fask',
          //   //     description: 'Licensing compliance and management of competitors and officials in one single dashboard!'
          //   // },{
          //   //     title: 'Dating App',
          //   //     nda: true,
          //   //     description:""
          //   //     // description: 'I used React Native to build the app functionality '
          //   // },{
          //   // title: 'Healthcare App',
          //   // nda: true,
          //   // description: 'Worked on refactoring legacy code, addressing poor coding patterns, and implementing new features. I worked extensively with React Native, Firebase and utilized Redux for state management.'
          // },
          // {
          //   //     title: 'Fintech Platform (Taxes Automation)',
          //   //     nda: true,
          //   //     description: 'Implement accounting features across the platform. Implemented a few features that were not team related, to ensure the whole functionality of the project between the teams flowed seamlessly.'
          //   // },{
          //   title: 'Porall - Human United (Healthcare App)',
          //   description: 'Worked on the app with React Native & MobX, implemented interesting features & breathalyzer functionalities.',
          //   links: ['https://apps.apple.com/rs/app/human-united/id1549504543', 'https://play.google.com/store/apps/details?id=com.porall.prevens'],
          //   // },{
          //   //     title: 'JanneApp (Nightlife App)',
          //   //     description: 'As the lead of the mobile app team at Janne company, I spearheaded the development of a social connection mobile app. Leveraging technologies such as React Native, Expo, and Redux for state management, I ensured efficient and seamless functionality.'
          // },
        ],
      },

      // {
      //   // time: 'May 2021 - Present · 2 yrs',
      //   time: '2021 - <span style="font-weight:500">Present</span>',
      //   title: 'Povio',
      //   previousTitle: '',
      //   position: 'Senior Software Engineer',
      //   // position: 'Senior Software Engineer · Freelance',
      //   content: ``,
      //   projects: [
      //     {
      //       // title: 'Eight Sleep (E-commerce)',
      //       title: 'E-commerce - Sleep Industry',
      //       nda: true,
      //       description: 'I used NextJS and integrated Shopify while restructuring and organizing the codebase at a NY tech company.',
      //       // description: 'I worked for a tech company based in NY. My responsibilities included developing with NextJS, integrating with Shopify, implementing features, and restructuring and reorganizing the codebase using the most up-to-date patterns.'
      //     },
      //     {
      //       // title: 'The7Bridges (Logistics & Data Science)',
      //       title: 'Logistics & Data Science - Supply Chains',
      //       nda: true,
      //       description: 'I refactored, added features, fixed bugs, and optimized code performance for a UK data science industry client. ',
      //       // description: 'I worked for a client operating in the data science industry in the UK. During my time with the client, I refactored the entire codebase, implemented new features, fixed bugs, and optimized the code performance. I primarily used React as the tech stack for these tasks.'
      //     },
      //     {
      //       // title: 'AptDeco (E-commerce Logistics)',
      //       title: 'E-commerce - Furniture Logistics',
      //       nda: true,
      //       description:
      //         'I worked on frontend tasks, including innovative feature implementation, for a NY logistics company specializing in second-hand sales.',
      //       // description: 'I worked for a logistics company based in NY specializing in the sale of second-hand items. During my time with the company, I tackled a range of frontend development tasks, including the implementation of several innovative features. My work involved addressing various issues throughout the codebase while striving to maintain high quality and efficient code. I primarily used NextJS for this project.'
      //     },
      //     {
      //       // title: 'The Coda Collection (Music Documentaries)',
      //       title: 'Music Documentaries',
      //       nda: true,
      //       description: 'I worked on the whole system - React, Contentful, Perimeter81, AWS, GraphQL and more across the project.',
      //       // description: 'I worked for a tech company based in NY. My responsibilities included developing with NextJS, integrating with Shopify, implementing features, and restructuring and reorganizing the codebase using the most up-to-date patterns.'
      //     },
      //   ],
      // },

      {
        // time: 'Feb 2021 - Feb 2022 · 1 yr 1 mo',
        time: '2021 - <span style="font-weight:500">2022</span>',
        title: 'Famous Media Inc.',
        previousTitle: '',
        position: 'Co-Founder',
        // position: 'Co-Founder · Freelance',
        content: `Co-Founder with the prime responsibility of orchestrating all resources and efforts into a single company-wide goal and strategy. I played a vital role in product improvement and implementation, bringing the product to life.`,
        // content: `Co-Founder coordinating resources for company goals and strategies, playing key role in product improvement and implementation.`,
        // projects: [{
        //     title: '',
        //     description: ''
        // }]
      },

      {
        // time: 'Sep 2020 - Jun 2021 · 10 mos',
        time: '2020 - <span style="font-weight:500">2021</span>',
        title: 'Toptal',
        previousTitle: '',
        // position: 'Software Developer · Freelance',
        position: 'Software Developer',
        content: ``,
        projects: [
          {
            title: 'Social Media Platform',
            nda: true,
            // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
            description: `I developed React Native features, fixed bugs, restructured projects, and managed AWS services for a CA client's backend.`,
          },
          {
            title: 'Video Communication App',
            nda: true,
            description: 'React Native engineer for NY client, implemented customized video features and assessed risk.',
          },
          {
            title: 'Data Science and Analytics',
            nda: true,
            description: 'Software Engineer in CO data science software company, worked on React features, bug fixes, and oversaw canvas, Unit and E2E tests.',
          },
        ],
      },

      {
        // time: 'Sep 2020 - Jun 2021 · 10 mos',
        time: '2020 - <span style="font-weight:500">2021</span>',
        title: 'Techframe',
        previousTitle: '',
        // position: 'Software Developer · Freelance',
        position: 'React & React Native Engineer',
        content: ``,
        projects: [
          {
            title: 'Shift Scheduling',
            nda: true,
            // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
            description: `React & React Native engineer responsible for implementing features, fixing bugs, supporting the team, and ensuring best programming patterns.`,
          },
        ],
      },

      {
        // time: 'Sep 2020 - Jun 2021 · 10 mos',
        time: '2018 - <span style="font-weight:500">2020</span>',
        title: 'Teco Technologies',
        previousTitle: '',
        // position: 'Software Developer · Freelance',
        position: 'Team Lead - React & React Native',
        content: ``,
        projects: [
          {
            title: 'Collaboration Platform',
            // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
            description: `As a Team Leader for React & React Native, I designed project architectures, guided the team towards project completion, and enforced best programming practices to maintain project organization.`,
          },
        ],
      },

      {
        // time: 'Sep 2020 - Jun 2021 · 10 mos',
        time: '2017 - <span style="font-weight:500">2018</span>',
        title: 'Beriflapp AG',
        previousTitle: '',
        // position: 'Software Developer · Freelance',
        position: 'Internship',
        content: ``,
        projects: [
          {
            title: 'Construction Saas',
            // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
            description: `During my internship, I learned project technologies and developed an approved test project. I supported the team with JavaScript knowledge and contributed to resolving complex technical issues.`,
          },
        ],
      },
    ],
    // openSource: [
    //     {
    //         // time: 'Sep 2020 - Jun 2021 · 10 mos',
    //         time: '2021 - <span style="font-weight:500">2022</span>',
    //         title: 'Dev-Tools',
    //         previousTitle: '',
    //         // position: 'Software Developer · Freelance',
    //         position: 'Swiss Army Knife toolkit for React & React Native Developers',
    //         content: `A toolkit to make the development process easy. Get high quality work done in less time.`,
    //         // projects: [{
    //         //     title: 'Collaboration Platform',
    //         //     // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //         //     description: `React & React Native Team Leader designing architecture, supporting team, ensuring best programming practices and organization.`,
    //         // }]
    //     }, {
    //         // time: 'Sep 2020 - Jun 2021 · 10 mos',
    //         time: '2020 - <span style="font-weight:500">2021</span>',
    //         title: 'CLIs & Boilerplate',
    //         position: 'Ease the work process',
    //         // position: 'Software Developer · Freelance',
    //         // position: 'Swiss Army Knife toolkit for React & React Native Developers',
    //         // content: `A toolkit to make the development process easy. Get high quality work done in less time.`,
    //         projects: [{
    //             subtitle: 'React CLI',
    //             // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //             description: ` - https:/github.com/arbershabani97/shabi-cli`,
    //         }, {
    //             subtitle: 'Cypress CLI',
    //             // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //             description: ` - https:/github.com/arbershabani97/shabi-cy-cli`,
    //         }, {
    //             subtitle: 'React Guide',
    //             // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //             description: ` - https:/github.com/arbershabani97/react-guide-project`,
    //         }]
    //     }, {
    //         // time: 'Sep 2020 - Jun 2021 · 10 mos',
    //         time: '2020 - <span style="font-weight:500">2021</span>',
    //         title: 'Libraries',
    //         previousTitle: '',
    //         // position: 'Software Developer · Freelance',
    //         position: 'Libraries to make state management easier',
    //         // content: `A toolkit to make the development process easy. Get high quality work done in less time.`,
    //         projects: [{
    //             subtitle: 'Axios-R',
    //             // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //             description: ` - https:/github.com/arbershabani97/axios-r`,
    //         }, {
    //             subtitle: 'Shabi-hooks',
    //             // description: 'Software Developer for a client in CA, whose responsibilities were React Native feature development, bug-fixing and project restructuring, implement dashboard features in React, backend improvements and features in NodeJS and managing the AWS services used for the project.'
    //             description: ` - https:/github.com/arbershabani97/shabi-hooks`,
    //         }]
    //     },
    // ],
    recommendations: [
      {
        name: 'Daniel Markowski',
        position: 'Team Leader & Mobile/Web developer | Netbulls',
        description: `I’ve got the pleasure of working with Arber for half a year. He is an open-minded always helpful person. He likes challenges and has plenty of ideas to solve any problem. I strongly recommend Arber as a software developer`,
      },
      {
        name: 'Henry Walker',
        position: 'Tech platforms & startups | Changing Digital',
        description: `I hired Arber as lead React Native developer on 2 projects.

                I'm delighted to recommend him as a highly capable and consultative professional, with excellent communication skills.
                
                He achieved everything that was required within the timeframes we needed to meet.
                
                On a personal note, he is a pleasure to work with.
                
                I look forward to working with Arber and the Coetic X team again in future.`,
      },
      {
        name: 'Rinor Hatashi',
        position: 'Chief Operating Officer | TechFrame',
        description: `Working with Arber was a very professional relationship, he has met every deadline with the most efficient work I have ever seen, his code was clean and understandable, he was a team player and fit perfectly with other team members. Vast knowledge in javascript including React JS and React Native, and how the Web & Mobile application functions at its core.`,
      },
      {
        name: 'Petro Tsaruk',
        position: 'Engineering Manager | Glassnode',
        description: `<div style="text-align:left;">
        
                Arber was hired as a Frontend Engineer for our Tax product team. He worked with us for
                approximately one and a half years and finished as a Product Engineer (full-stack). I had daily
                interactions with him as an Engineering Manager.
<br/><br/>
                Arber’s main mission was to contribute to our crypto tax and portfolio tracking software product
                and help us improve our tax value proposition. The main technologies used were NestJS,
                React, and PostgreSQL.
<br/><br/>
                Arber's performance is exceptional when it comes to the front end. His React experience has
                helped us on numerous occasions. Arber suggested and implemented various solutions to all
                kinds of problems we had. One of his projects required extensive knowledge of tax regulations
                in different countries. Arber was able to gain that knowledge and cross-reference it with our tax
                experts to implement a very domain-specific solution, which greatly increased our product's
                accuracy. After that project, Arber acquired shared ownership of that part of the platform and
                became one of the go-to people in that specific domain.
<br/><br/>
                Arber was very diligent in learning how our product works. When faced with a blocker, he would
                find a potential solution and validate it with his peers. Arber was very careful when he was
                dealing with something new, and he would focus on consolidating his solutions with tests which
                resulted in a high-quality delivery.
<br/><br/>
                I would describe Arber as a reliable problem solver. He is dedicated, adaptable, and willing to
                learn. As a side note, Arber has a wide range of hobbies, such as playing chess, piano, and
                flying helicopters.
<br/><br/>
                Arber didn't have any problems integrating into the company and became a valuable team
                member in no time. Cross-team communication and collaboration are a must in our company,
                and Arber had no issues building new connections and gaining knowledge from other parts of
                the platform.
<br/><br/>
                Arber’s contract was terminated due to a restructuring exercise within the business. And we
                thoroughly regret to see them go.
<br/><br/>
                I would like to thank Arber for his excellent contribution. We wish him every success and all the
                best for his future professional and private life. I would highly recommend Arber for any position
                that requires an experienced senior front-end engineer. He is a dedicated and talented
                employee who would be a valuable asset to any organisation.</div>`,
      },
    ],
  };

  // const experiences = experiencesList
  const experiences = page === 1 ? experiencesList.slice(0, 1) : page === 2 ? experiencesList.slice(0, 4) : experiencesList.slice(4);
  const recommendations = page === 3 ? recommendationsList.slice(0, 3) : recommendationsList.slice(3);
  // const recommendations = recommendationsList;

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=0.8" />
      <div className="CV">
        <div className="CV--header">
          <div className="CV--header-left-absolute"></div>
          <div className="CV--header-left">
            <img src={image} alt="profile" />
          </div>
          <div className="CV--header-right-absolute"></div>
          <div className="CV--header-right">
            <h1 className="CV--header-name">{name}</h1>
            <p className="CV--header-title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        <div className="CV--body">
          <div className="CV--left-sidebar-absolute"></div>
          <div className="CV--left-sidebar">
            {page === 1 && (
              <>
                <div className="CV--left-box CV--left-profile">
                  <h4 className="CV--left-box-header">Profile</h4>
                  {profile.map(({ text, icon }) => (
                    <div className="CV--left-box--content">
                      {icon}
                      {text}
                    </div>
                  ))}
                </div>
                <div className="CV--left-box CV--left-contact">
                  <h4 className="CV--left-box-header">Contact</h4>
                  {contact.map(({ text, icon }) => (
                    <div className="CV--left-box--content">
                      {icon}
                      {text}
                    </div>
                  ))}
                </div>
                <div className="CV--left-box CV--left-contact">
                  <h4 className="CV--left-box-header">Expert Talks</h4>
                  {talks.map(({ text, icon, image, link }) => (
                    <>
                      {/* <div className='CV--left-box--content CV--left-box--content--interests'>
                                        {icon}
                                        {text}
                                    </div> */}
                      <img src={image} alt="img" />
                      <a href={link} target="_blank" rel="noreferrer">
                        {link}
                      </a>
                    </>
                  ))}
                </div>

                <div className="CV--left-box CV--left-interests">
                  <h4 className="CV--left-box-header">Hobbies & Interests</h4>
                  {interests.map(({ text, icon, image }) => (
                    <>
                      {/* <img src={image}/> */}
                      <div className="CV--left-box--content CV--left-box--content--interests">
                        {icon}
                        {text}
                      </div>
                    </>
                  ))}
                </div>
                <div className="CV--left-box CV--left-interests" style={{ position: 'absolute', bottom: 0, opacity: 0.3 }}>
                  {/* <h4 className="CV--left-box-header">Hobbies & Interests</h4> */}

                  <a
                    className="CV--left-box--content CV--left-box--content--interests"
                    href="https://coetic-x.com/cv/arber-shabani" target="_blank" rel="noreferrer"
                    style={{ fontSize: 9, textAlign: 'center' }}>
                    https://coetic-x.com/cv/arber-shabani
                  </a>
                </div>
              </>
            )}
            {/* {page===2 && (

                                <div className='CV--left-box CV--left-interests'>
                                <h4 className='CV--left-box-header'>Hobbies & Interests</h4>
                                {interests.map(({ text, icon, image }) => (
                                    <>
                                    <img src={image}/>
                                    <div className='CV--left-box--content CV--left-box--content--interests'>
                                        {icon}
                                        {text}
                                    
                                    </div>
                                    </>
                                ))}
</div>
                        )} */}
          </div>
          <div className="CV--right-sidebar-absolute"></div>
          <div className="CV--right-sidebar">
            {page === 1 && (
              <div className="CV--right-header">
                <h3 className="CV--right-box-header">About Me</h3>
                <p>{about}</p>
              </div>
            )}
            <div className="CV--right-content">
              {Boolean(page === 1 || page === 2 || page === 3) && (
                <>
                  <h3 className="CV--right-box-content">Experience</h3>
                  {experiences.map(({ time, title, previousTitle, position, projects, content }) => {
                    
                    return (
                      <div className="CV--right-box-content-exp">
                        <p dangerouslySetInnerHTML={{ __html: time }} />
                        <div>
                          <h6>
                            {title} {page === 2 && title==="Coetic X" ? <span style={{ lineHeight:'auto',fontSize: 14,fontWeight:200, opacity:0.3}}> (continued)</span> : <span>{previousTitle}</span>}
                          </h6>
                          <p className="CV--right-box-content-exp--position">{position}</p>
                          {Boolean(content) && <p className="CV--right-box-content-exp--content">{content}</p>}
                          {Boolean(projects?.length) &&
                            projects.map(({ title: projectTitle, description, nda = false, links, projects:subProjects }, i) => {
                              if(i>3 && page ===1) return null;
                              if(i<=3 && page ===2 && title === 'Coetic X') return null;
                              return (
                                <p className="CV--right-box-content-exp--project">
                                  <span>{projectTitle}</span>
                                  {nda && <span className="CV--right-box-content-exp--project---NDA"> - NDA</span>}
                                  <br />
                                  {description}
                                  {Boolean(links?.length) &&
                                    links.map(link => (
                                      <>
                                        <br />
                                        <a href={link} target="_blank" rel="noreferrer">
                                          {link}
                                        </a>
                                      </>
                                    ))}
                                  {Boolean(subProjects?.length) &&
                                    subProjects.map((project,j) => {
    
                                      return (
                                        <span style={{display:"block"}} className='CV--right-box-content-exp--project--project'>
                                          <span className="project-title">{project.title}</span>{Boolean(project?.titleExtras) && (<span className="project-subtitle"> - {project.titleExtras}</span>)} {Boolean(project?.role) && (<>- <b>{project.role}</b></>)}
                                          {/* - <span>{project.duration}</span> */}
                                          <br />
                                          <span>{project.description}</span>
                                        </span>
                                      )
                                    })}
                                </p>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
              {page === 3 && openSource?.length && (
                <>
                  <br />
                  <h3 className="CV--right-box-content">Open Source</h3>
                  {openSource.map(({ time, title, previousTitle, position, projects, content }) => (
                    <div className="CV--right-box-content-exp">
                      <p dangerouslySetInnerHTML={{ __html: time }} />
                      <div>
                        <h6 className="CV--right-box-content-exp--open-source">
                          {title} <span>{previousTitle}</span>
                        </h6>
                        <p className="CV--right-box-content-exp--position">{position}</p>
                        {Boolean(content) && <p className="CV--right-box-content-exp--content">{content}</p>}
                        <p className="CV--right-box-content-exp--project">
                          {Boolean(projects?.length) &&
                            projects.map(({ title, subtitle, description }) => (
                              <>
                                {title ? (
                                  <>
                                    <span>{title}</span>
                                    <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {subtitle ? <span className="CV--right-box-content-exp--project-subtitle">{subtitle}</span> : ''}
                                {description}
                                <br />
                              </>
                            ))}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {Boolean(page === 3 || page === 4) && (
                <>
                  <br />
                  <br />
                  <h3 className="CV--right-box-content text-center">Recommendations</h3>
                  {recommendations.map(({ description, name, position }) => (
                    <div className="CV--right-box-content-recommend">
                      {/* <p dangerouslySetInnerHTML={{__html: time}}/> */}
                      <div>
                        <h6>{name}</h6>
                        <p className="CV--right-box-content-recommend--position">{position}</p>
                        {Boolean(description) && <p className="CV--right-box-content-recommend--content" dangerouslySetInnerHTML={{ __html: description }} />}
                      </div>
                    </div>
                  ))}
                </>
              )}
              <div className="CV--right-box-buttons">
                {page !== 1 && (
                  <button className="no-print left" onClick={() => setPage(a => a - 1)}>
                    <span class="material-icons">keyboard_double_arrow_left</span> Previous
                  </button>
                )}
                {page !== 4 && (
                  <button className="no-print right" onClick={() => setPage(a => a + 1)}>
                    Next <span class="material-icons">keyboard_double_arrow_right</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
